import Swipe from "swipejs";
import queryString from "query-string";
// import { emitter } from "@marvelapp/react-ab-test";
// import { AB_TEST_NAME } from "../constant";
import { useEffect } from "react";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`


  body {
    overflow: hidden;
    color: var(--gray100);
  }

  p {
    letter-spacing: -0.004em;
    line-height: 150%;
    font-style: normal;
  }

  a {
    text-decoration: none;
  }

  .hide {
    opacity: 0;
  }

  .btn {
    line-height: 160%;
    border-radius: 4px;
    font-weight: 500;
    display: inline-block;
  }

  .btn-wide {
    width: 100%;
  }

  .btn:active {
    filter: brightness(92%);
    transform: scale(0.99);
    transition: all 200ms;
  }

  .btn:hover {
    cursor: pointer;
  }

  .onboarding-section {
    transition: opacity 0.35s;
  }

  /* header */
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 13.333vw;
    width: 100%;
  }

  .btn-icon {
    display: flex;
    padding: 3.333vw;
    margin-left: 1.111vw;
    transition: all 200ms;
    background: #fff;
    border-radius: 100% !important;
  }

  .btn > img {
    width: 6.667vw;
    height: 6.667vw;
  }

  .btn-header {
    padding: 1.667vw 2.222vw;
    margin-right: 2.222vw;
    font-size: 3.889vw;
    background: #fff;
    border-radius: 1.111vw;
    color: var(--gray500);
    transition: all 200ms;
  }

  /* section */
  .swipe-section {
    overflow: hidden;
    visibility: hidden;
    position: relative;
  }
  .swipe-wrap {
    position: relative;
    height: calc(100vh - (56px + 24px + 48px));
  }
  .content {
    float: left;
    width: 100%;
    position: relative;
    min-height: 416px;
    display: flex;
    flex-direction: column;
    z-index: -10;
  }

  .img-box {
    height: auto;
    width: 100%;
    max-width: 540px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  .img-box > img {
    height: auto;
    width: 100%;
  }
  .onboarding-text-box {
    position: relative;
    padding: 6.6667vw 8.889vw 0;
    max-width: 540px;
    width: 100%;
  }

  .onboarding-title {
    font-size: 4.167vw;
    font-weight: 700;
    text-align: left;
    color: var(--green200);
    margin-bottom: 2.222vw;
  }

  .describe {
    font-size: 6.667vw;
    font-weight: 700;
    text-align: left;
  }

  .tooltip {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: fit-content;
    max-width: 540px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation-duration: 2s;
    animation-name: tooltipAni;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: ease-in-out;
  }

  .tooltip > p {
    background: var(--gray100);
    font-size: 4.167vw;
    padding: 2.778vw 3.889vw;
    color: var(--white);
    border-radius: 0.833vw;
    box-shadow: 0 2.5vw 6.667vw rgba(42, 42, 42, 0.15);
  }

  .tooltip > svg {
    transform: rotate(180deg);
  }

  @keyframes tooltipAni {
    0% {
      bottom: 94%;
    }
    50% {
      bottom: 104%;
    }
    100% {
      bottom: 94%;
    }
  }

  /* controls */
  .controls-wrapper {
    position: fixed;
    width: 100%;
    bottom: 24px;
    margin: 0 auto;
  }
  .controls {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 6.667vw 0 8.889vw;
  }

  .indicator {
    display: flex;
    align-items: center;
  }

  .dot {
    height: 1.667vw;
    width: 1.667vw;
    border-radius: 50%;
    background: var(--green200);
    opacity: 0.3;
    margin-right: 2.222vw;
    transition: all 0.5s;
  }

  .dot.active {
    opacity: 1;
    transform: scale(1.4);
  }

  .btn-float {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    background: var(--green200);
    height: 56px;
    width: 56px;
    border-radius: 100% !important;
    right: 0;
    transition: all 200ms;
  }

  .btn-float.active:after {
    content: "";
    background: rgba(0, 220, 108, 0.3);
    border-radius: 100%;
    position: absolute;
    width: 100%;
    height: 100%;
    transform: scale(1.3);
    z-index: -10;
    animation-duration: 0.7s;
    animation-name: lastPage;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }

  @keyframes lastPage {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(1.3);
    }
  }

  /* home-section */
  .home-section {
    display: none;
    opacity: 0;
    transition: opacity 0.5s;
  }

  .home-section > header {
    display: none;
  }

  .show {
    opacity: 1;
  }

  .ellipse {
    position: absolute;
    opacity: 1;
    width: 10px;
    height: 10px;
    background: var(--green200);
    border-radius: 100%;
    z-index: -10;
    transition: width 0.35s, height 0.35s, opacity 0.5s;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .ellipse-active {
    width: calc(100vw * 3);
    height: calc(100vw * 3);
    opacity: 0;
  }

  .btn-wrapper {
    position: relative;
  }

  .logo {
    height: 5.556vw;
    width: auto;
    margin: 3.889vw 4.444vw;
  }

  .img-box > .home-img {
    background-image: url(${process.env.PUBLIC_URL + "img/img_home_sm.png"});
    background-position: center;
    /*background-size: auto 100vw;*/
    background-size: 100%;
    background-repeat: no-repeat;
    /*height: 100%;*/
    padding-bottom: 75%;
    width: 100%;
  }

  .home-text-box-wrapper {
    position: relative;
    text-align: center;
    flex-direction: column;
  }

  .home-text-box {
    margin-top: 6.6667vw;
  }

  .home-subtitle {
    font-size: 4.444vw;
    margin-bottom: 1.111vw;
    font-weight: 500;
  }

  .people-count {
    position: relative;
    display: inline-flex;
    overflow: hidden;
    height: max-content;
    color: var(--green100);
    font-size: 6.667vw;
    font-weight: 700;
  }

  .people-count > span {
    position: relative;
    top: -200px;
    transition: top 0.2s;
  }

  .home-title {
    font-weight: 700;
    font-size: 7.778vw;
    line-height: 150%;
  }

  .home-title > span {
    position: relative;
  }

  .home-title > span:after {
    content: "";
    display: block;
    width: 100%;
    height: 3.333vw;
    position: absolute;
    bottom: 1.111vw;
    z-index: -10;
    left: 0;
    background: var(--green200);
    opacity: 0.3;
  }

  .home-section {
    position: relative;
    width: 100%;
  }

  .home-section > .controls-wrapper {
    text-align: center;
    padding: 0 6.667vw;
    bottom: 16px;
  }

  .home-section > .controls-wrapper > div:first-child {
    margin-bottom: 2.222vw;
  }

  .btn-primary {
    font-size: 4.444vw;
    padding: 2.222vw 3.333vw;
    background: var(--green200);
    color: var(--white);
  }

  .btn-second {
    color: var(--gray400);
    padding: 2.222vw 3.333vw;
    font-size: 3.889vw;
    font-weight: 400;
  }

  .btn-login {
    padding: 1.666666667vw 3.333vw;
    font-size: 3.888888889vw;
    font-weight: 400;
    color: var(--gray450);
  }

  .login-typo {
    color: var(--greendark);
    font-weight: 500;
    margin-left: 5px;
  }

  .footer {
    display: flex;
    flex-direction: column;
    gap: min(2.222vw, 16px);
  }
  .btn-second > b {
    color: var(--green100);
    font-weight: 500;
  }
  

  /* responsive */
  @media screen and (min-width: 540px) {
    header {
      height: 72px;
    }

    .btn-icon {
      padding: 18px;
      margin-left: 6px;
    }

    .btn > img {
      width: 36px;
      height: 36px;
    }

    .btn-header {
      padding: 9px 12px;
      margin-right: 12px;
      font-size: 21px;
      border-radius: 6px;
    }

    .onboarding-title {
      font-size: 20px;
    }

    .describe {
      font-size: 32px;
      text-align: center;
    }

    .onboarding-text-box {
      padding: 36px 0;
      margin: 0 auto;
    }

    .onboarding-title {
      margin-bottom: 12px;
      text-align: center;
    }

    .controls {
      padding: 0 36px;
    }

    .swipe-wrap {
      display: flex;
      align-items: center;
    }

    .content {
      top: -3%;
    }

    .tooltip > p {
      font-size: 18px;
      padding: 12px 18px;
      border-radius: 6px;
    }

    .dot {
      height: 8px;
      width: 8px;
      margin-right: 12px;
    }

    .btn-float {
      padding: 18px;
      height: 72px;
      width: 72px;
    }

    /*  home  */
    .home-section > header {
      display: flex;
    }

    .logo {
      height: 30px;
      margin: 21px 24px;
    }

    .home-text-box-wrapper {
      margin: initial;
      height: calc(100vh - (48px + 16px + 87px));
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      top: -5vh;
    }

    .home-text-box {
      text-align: center;
      position: relative;
      margin-top: 32px;
    }

    .home-subtitle {
      font-size: 24px;
      margin-bottom: 2px;
    }

    .people-count {
      font-size: 36px;
    }

    .home-title {
      font-size: 42px;
    }

    .home-title > span:after {
      height: 16px;
      bottom: 6px;
    }

    .home-section > .controls-wrapper {
      padding: 0 36px;
      bottom: 24px;
    }

    .home-section > .img-box {
      max-width: 540px;
    }

    .img-box > .home-img {
      background-image: url(${process.env.PUBLIC_URL + "img/img_home_lg.png"});
      background-size: contain;
    }

    .home-section > .controls-wrapper > div:first-child {
      margin-bottom: 12px;
    }

    
    .btn-primary {
      font-size: 20px;
      padding: 12px 24px;
    }

    .btn-second {
      padding: 14px 24px;
      font-size: 18px;
      height: 62px;
    }

    .btn-login {
      padding: 14px 24px;
      font-size: 18px;
      height: 62px;
    }
    .footer {
      gap: 8px;
    }
  }
`;

function OnBoardingA() {
  useEffect(() => {
    // pure JS
    const element = document.getElementById("mySwipe"),
      backBtn = document.getElementById("back"),
      nextBtn = document.getElementById("next"),
      dot = document.querySelectorAll(".dot"),
      onboardingSection = document.getElementById("onboardingSection"),
      homeSection = document.getElementById("homeSection"),
      ellipse = document.getElementById("ellipse"),
      peopleCount = document.getElementById("peopleCount");

    let doubleSubmitPreventer = true; // double submit preventer

    var mySwipe = new Swipe(element, {
      startSlide: 0,
      // auto: false,
      draggable: true,
      autoRestart: false,
      continuous: false,
      disableScroll: true,
      stopPropagation: true,
      callback: function (index, element) {
        // history back button show and hide
        if (index === 0) {
          backBtn.classList.add("hide");
        } else {
          backBtn.classList.remove("hide");
        }

        // indicator dot show and hide
        for (let i = 0; i < dot.length; i++) {
          dot[i].classList.remove("active");
        }

        dot[index].classList.add("active");

        // last page's floating button animation and event
        if (index === dot.length - 1) {
          nextBtn.classList.add("active");

          console.log(nextBtn.classList.contains("active"));

          if (
            index === dot.length - 1 &&
            nextBtn.classList.contains("active")
          ) {
            nextBtn.addEventListener("click", nextBtnClickEvent);
          }
        } else {
          nextBtn.classList.remove("active");
          nextBtn.removeEventListener("click", nextBtnClickEvent);
        }
      },
      transitionEnd: function (index, element) {},
    });

    // next button event
    function nextBtnClickEvent() {
      if (doubleSubmitPreventer) {
        doubleSubmitPreventer = false;

        ellipse.classList.add("ellipse-active");

        setTimeout(function () {
          onboardingSection.classList.add("hide");
        }, 100);

        setTimeout(function () {
          onboardingSection.style.display = "none";
          homeSection.style.display = "block";
        }, 400);

        setTimeout(function () {
          homeSection.classList.add("show");
          addPeopleCountItem();
        }, 450);
      }
    }

    // paint count
    let userNum = 219525;

    function addPeopleCountItem() {
      peopleCount.innerText = "";

      let numString = numberWithCommas(userNum);
      let array = numString.split("");

      for (let i = 0; i < array.length; i++) {
        let span = document.createElement("span");
        span.textContent = array[i] + "";
        peopleCount.appendChild(span);
      }

      // paint animation
      let num = 0;
      let timerId = setInterval(() => {
        peopleCount.childNodes[num].style.top = 0;

        if (num < array.length - 1) {
          num++;
        } else {
          setTimeout(() => clearInterval(timerId));
        }
      }, 50);
    }

    // paint comma
    function numberWithCommas(num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    function init() {
      backBtn.onclick = mySwipe.prev;
      nextBtn.onclick = mySwipe.next;
    }

    init();
  }, []);

  return (
    <>
      <GlobalStyle />
      {/*  onboarding-section  */}
      <div className='onboarding-section' id='onboardingSection'>
        {/*  header  */}
        <header>
          <div className='btn btn-icon hide' id='back'>
            <img
              src={process.env.PUBLIC_URL + "img/arrow-back.svg"}
              alt='back icon'
            />
          </div>

          <a
            className='btn btn-header'
            id='skip'
            href={`https://student.mildang.kr`}
          >
            Skip
          </a>
        </header>
        {/*  swipe-section  */}
        <div id='mySwipe' className='swipe-section'>
          <div className='swipe-wrap'>
            <div className='content'>
              <div className='img-box'>
                <img
                  alt='img_b_1'
                  src={process.env.PUBLIC_URL + "img/img_b_1.png"}
                />
              </div>
              <div className='onboarding-text-box'>
                <p className='onboarding-title'>스터디 그룹</p>
                <p className='describe'>
                  중도 포기율이 49% 더 낮은
                  <br />
                  스터디 그룹 단어 학습
                </p>
              </div>
            </div>
            <div className='content'>
              <div className='img-box'>
                <img
                  alt='img_b_2'
                  src={process.env.PUBLIC_URL + "img/img_b_2.png"}
                />
              </div>
              <div className='onboarding-text-box'>
                <p className='onboarding-title'>맞춤형 복습</p>
                <p className='describe'>
                  인공지능 &amp; 망각곡선 분석을
                  <br /> 통한 복습 단어장 제공
                </p>
              </div>
            </div>
            <div className='content'>
              <div className='img-box'>
                <img
                  alt='img_b_3'
                  src={process.env.PUBLIC_URL + "img/img_b_3.png"}
                />
              </div>
              <div className='onboarding-text-box'>
                <div className='tooltip'>
                  <p>1:1 집중관리 프로그램 신청시 이용 가능⚡️</p>
                  <svg
                    width={10}
                    height={5}
                    viewBox='0 0 10 5'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M3.85449 1.14551C4.26386 0.736144 4.46854 0.531461 4.70596 0.458421C4.89756 0.399478 5.10244 0.399478 5.29404 0.458421C5.53146 0.531461 5.73614 0.736145 6.14551 1.14551L10 5L0 5L3.85449 1.14551Z'
                      fill='#2A2A2A'
                    />
                  </svg>
                </div>
                <p className='onboarding-title'>1:1 선생님 매칭</p>
                <p className='describe'>
                  실시간 질문과 맞춤자료로
                  <br /> 똑똑하게 내신 &amp; 수능 대비
                </p>
              </div>
            </div>
          </div>
        </div>
        {/*  controls  */}
        <div className='controls-wrapper'>
          <div className='controls'>
            <div className='indicator' id='indicator'>
              <div className='dot active' />
              <div className='dot' />
              <div className='dot' />
            </div>
            <div className='btn-wrapper'>
              <div className='btn btn-float' id='next'>
                <img
                  src={process.env.PUBLIC_URL + "img/arrow-forward.svg"}
                  alt='next icon'
                />
              </div>
              <span className='ellipse' id='ellipse'></span>
            </div>
          </div>
        </div>
      </div>
      {/*  home-section  */}
      <div className='home-section' id='homeSection'>
        {/*  header  */}
        <header>
          <img
            className='logo'
            src={process.env.PUBLIC_URL + "img/logo.png"}
            alt='logo'
          />
        </header>
        <div className='home-text-box-wrapper'>
          <div className='img-box'>
            <div className='home-img' />
          </div>
          <div className='home-text-box'>
            <div className='home-subtitle'>
              <div className='people-count' id='peopleCount'>
                15,723
              </div>{" "}
              밀당러와 함께하는
            </div>
            <div className='home-title'>
              실패하지 않는
              <br />
              똑똑한 영어 학습 비법
            </div>
          </div>
        </div>
        <div className='controls-wrapper footer'>
          <a
            className='btn btn-primary btn-wide'
            id='signUp'
            href={`https://student.mildang.kr/onboarding/signup?${queryString.stringify(
              {
                utm_source: "onboarding",
                utm_medium: "promoting_registration",
                utm_campaign: "b",
              }
            )}`}
          >
            학습 여정 시작하기
          </a>
          <a
            className='btn-login'
            href={`https://student.mildang.kr?${queryString.stringify({
              utm_source: "onboarding",
              utm_medium: "promoting_registration",
              utm_campaign: "a",
            })}`}
          >
            이미 사용중인 계정이 있으신가요?
            <span className='login-typo'>로그인</span>
          </a>
        </div>
      </div>
    </>
  );
}

export default OnBoardingA;
