import { emitter } from "@marvelapp/react-ab-test";
// import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { AB_TEST_NAME } from "./constant";
// import OnBoardingA from "./OnBoardingA";
import OnBoardingB from "./OnBoardingB";

// import Redirect from "./Redirect";

emitter.defineVariants(AB_TEST_NAME, ["A", "B"]);

// Called when the experiment is displayed to the user.
emitter.addPlayListener(function (experimentName, variantName) {
  console.log(`Displaying experiment ${experimentName} variant ${variantName}`);
});

// Called when a 'win' is emitted, in this case by this.experimentRef.current.win()
emitter.addWinListener(function (experimentName, variantName) {
  console.log(
    `Variant ${variantName} of experiment ${experimentName} was clicked`
  );
});

function App() {
  return (
    <OnBoardingB />
    // <Router>
    //   <Switch>
    //     <Route path='/a'>
    //       <OnBoardingA />
    //     </Route>
    //     <Route path='/b'>
    //     </Route>
    //     {/* <Route path='/c'>
    //       <OnBoardingC />
    //     </Route> */}
    //     <Route>
    //       <Experiment name={AB_TEST_NAME}>
    //         {/* <Variant name='A'>
    //           <Redirect path='/a' />
    //         </Variant> */}
    //         <Variant name='B'>
    //           <Redirect path='/b' />
    //         </Variant>
    //         {/* <Variant name='C'>
    //           <Redirect path='/c' />
    //         </Variant> */}
    //       </Experiment>
    //     </Route>
    //   </Switch>
    // </Router>
  );
}

export default App;
